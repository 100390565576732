.download-button {
  padding-top: 3px !important;
  border: 1px solid #3e8eff !important;
  color: #3e8eff;
  background-color: #fff;
  border-radius: 8px;
  margin-right: 10px;
  text-transform: none;
  font-size: 14px;
  height: 36px;
  padding: 0 15px 0;
  line-height: 1;
}

.submit-document-button {
  background-color: #3e8eff;
  color: #fff;
  border: 1px solid #3e8eff;
  border-radius: 8px;
  margin-right: 10px;
  text-transform: none;
  font-size: 14px;
  height: 36px;
  padding: 0 15px 0;
  line-height: 1;
  cursor: pointer;
}

.undo-redo-icon {
  font-size: larger;
  -webkit-text-stroke: 1px;
}

.disabled-undo-redo-button {
  border: 1px solid lightgray !important;
  opacity: 0.8;
  color: #707070;
  background-color: #fff;
  border-radius: 8px;
  margin-right: 10px;
  text-transform: none;
  font-size: 14px;
  height: 36px;
  padding: 0 15px 0;
  line-height: 1;
}

.add-pdf-button {
  /* margin-left: 10px; */
  height: 100px;
  width: 100px;
  font-size: 65px;
  font-weight: 800;
  color: #ced4da;
}

.add-pdf-button :hover {
  color: #3e8eff;
  cursor: pointer;
}

.custom-editor-page canvas {
  max-width: none !important;
}

/* this css added for solve model opening issue in e-sign templates */
.textLayer {
  z-index: 0 !important;
}

/* this css added for make pdf flat and uneditable in pdf editor after merge */
.merge-pdf__page .annotationLayer .textWidgetAnnotation :is(input, textarea),
.merge-pdf__page .annotationLayer .choiceWidgetAnnotation select,
.merge-pdf__page .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: none !important;
  pointer-events: none !important;
}

.size-changer:hover {
  border: 2px solid #1976D2 !important;
}

.add-singature-section .MuiBox-root {
  width: 100% !important;
  padding: 0 15px 0 20px;
}

.add-singature-section {
  margin-bottom: 40px !important;
}

/* for stop logo overlaping on signature drop options */
.flex-1 {
  flex: 1;
}

/* For set the font size for the placeholder text */
/* 
textarea::placeholder {
  font-size: 13px;
} 
*/