:root {
  --body-background-color: #F7F9FC;
  --title-color: #092540;
  --primary-color: #0f294b;
  --tab-color: #4E5C78;
  --sidebar-color: #435366;
  --sidebar-active-color: #0063A6;  
  --border-color: #D5DDE6;  
  -body-color: #444444;    
}

/*
var(--border-color);
*/
html, body {
    margin:0;
    padding:0;
    height: auto !important;
    min-height: 100%;
    /* width: 100%;     */
}
body {
    font-family: "Source Sans Pro", sans-serif;
    background-color: var(--body-background-color) !important;
    min-height: 100vh ;
}
*+h1, *+h2, *+h3, *+h4, *+h5, *+h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}
.uk-h1, h1 {
    font-family: 'Montserrat', sans-serif;  
    font-size: 1.75rem;
    margin: 15px 0;
    letter-spacing: -0.25px;    
    color: #092540;
}
.uk-h2, h2 {
    font-family: 'Montserrat', sans-serif;  
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: -0.25px;    
    color: #092540 !important
}
.uk-h3, h3 {
    font-family: 'Montserrat', sans-serif;  
    font-size: 1.25rem;
    font-weight: bold;  
    margin-top: 5px;
    color: #092540 !important
}
.uk-h4, h4 {
    margin-bottom: 10px;
    color: #092540;
    font-size: 1.1rem;    
}
.uk-h5, h5 {
    font-size: 1.1rem;
}
input[type='radio'], input[type='checkbox'] {
    margin-right: 8px;
    float: left;
}
.content {
    max-width: 1024px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
}
.content.reports {
    max-width: 100%;
}
.view-report {
    margin: 20px 10px !important;
}
.view-report .uk-breadcrumb {
    margin: 0;
    padding: 0;
}    
.view-report h1 {
    margin: 0 0 5px 0;
    padding: 0;
}    

.reports-filter {
    background-color: #fff;
    padding: 10px;    
    margin: 0;
    border: 1px solid rgba(0,0,0,.12);
}
.reports-filter .uk-select {
    max-width: 200px;
    margin-right: 10px;
}
.reports-filter .uk-input {
    max-width: 170px;
    margin-right: 10px;
}
.reports select, .reports input {
    margin-right: 10px;
}
.content.add-forms {
    padding: 0 20px;
}
.content.transaction-detail {
    margin: 0;
    padding: 0;
}
.uk-container-small {
    max-width:  100%;
}
.uk-navbar-left-user {
    margin-left: 100px;
    color: #fff;
    padding: 7px;
}   
.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
    min-height: 60px;
    padding: 0;
}
.transaction-details h5 {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: -15px;
}
.tm-main {
    background-color: #F7F9FC;  
    margin-top: 20px;
}
/* -----v------ */

.uk-container-main-section{
    box-sizing: border-box !important;
    padding: 20px !important;
}
.right-side-tm-main-section .uk-container{
    margin: 10px;
}
.right-side-tm-main-section .uk-container .form-container{
    padding: 20px !important;
}
.right-side-tm-main-section .uk-accordion {
    margin-bottom: 0px;
}
.right-side-tm-main-section .scroll-div{    
    overflow-y: auto;
    border: 1px solid #eaeaea;
    border-radius: 10px;
}
.right-side-tm-main-section .scroll-div .scroll-fix-div{
    min-width: 1299px;
    margin-bottom: 0px;
}
.right-side-tm-main-section .btn-div{
    display: flex;
    justify-content: flex-end;
}   
@media(max-width: 1199px){
    .right-side-tm-main-section .btn-div{
        justify-content: flex-start;
    }
}
@media(max-width: 960px){
    .uk-container-main-section{
        margin-top: 60px !important;
        padding: 20px !important;
    }
    .uk-container-main-section .uk-label{
        padding: 8px 10px;
    }
}
/* ------v------ */
.uk-offcanvas-bar {
    width: 640px;
    background-color: #fff; 
}
 .uk-offcanvas-flip .uk-open>.uk-offcanvas-bar {
    padding: 15px 10px;
 }
 .uk-offcanvas-bar h2 {
     border-bottom: 1px solid #ccc;
     padding-bottom: 10px;
     padding-left: 25px;
}
.uk-offcanvas-bar .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
    margin-top: 5px;
}
.uk-offcanvas-bar .toy-detail {
    margin-bottom: 20px;
}
.toys-line-separator {
    border-bottom: 1px solid #ccc;
}
.uk-modal-body {
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
}
.uk-modal-body.brokerage {
    padding: 5px 20px 0 20px;
    margin-bottom: 20px;
}
.uk-modal-body h3 {
    margin: 0;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #ccc;  
    margin-bottom: 15px;
}
.form-container {
    padding: 0;
    padding: 30px !important;
    box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;

    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
}
/*
form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 100px);   
    grid-gap: 20px;
}
*/
.form-row {
    width: 100%;
    display: inline-block;  
}
.form-row .tetra-column {
    width: 25%;
    display: inline-block;  
    float: left;
}
.form-row .one-column {
    width: 31%;
    min-width: 110px !important;
    display: inline-block;  
    float: left;
}
.form-row .half-column {
    width: 48%;
    display: inline-block;  
    float: left;
}
.form-row .two-column {
    width: 64%;
    display: inline-block;  
    float: left;
}
.form-row .two-column {
    width: 62%;
    display: inline-block;  
    float: left;
}
.form-row .full-column {
    width: 93%;
    display: inline-block;  
    float: left;
}
.form-row .three-column {
    width: 100%;
    display: inline-block;  
    float: left;
}
.form-row.preview {
    background-color: #fff;
    padding: 10px 20px;
    margin: 10px 0;
}
.form-row ul {
    padding: 0;
}
form.uk-search.uk-search-default {
    max-width: 300px;
    float: right;
}
.esign-send.purchase-sale-agreement {
    margin-top: 30px;
}
.uk-tab {
    margin-left: 0;
    margin-bottom: 0;
}
.uk-tab>*>a {
    font-size: 16px;
    color: var(--tab-color);
    text-transform: none;
}
.uk-tab>.uk-active>a {
    color: #0063A6;
    border-bottom: 2px solid #0063A6;
}
.uk-tab>* {
    padding-left: 0;
}
.uk-tab::before {
    left: 0px;
}
.uk-container {
    margin-left: 20px;
    margin-right: 20px;
}
.uk-container.transaction-details {
    padding: 10px 0 0 20px;
    border-bottom: 1px solid #ddd;
    margin: 0;
    width: 100%;
    max-width: 100%;
}
.uk-container.view-listing, .uk-container.view-deal {
    margin-top: 15px;
}
.uk-container.page {
    padding: 10px 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
}
.uk-container.page .uk-grid-small {
    padding: 0 10px;
}
.uk-container.transaction-details h1 {
    float: left;
    margin-right: 15px;
}
.uk-container.transaction-details .uk-breadcrumb {
    margin: 0 0 5px 0;
}
.uk-breadcrumb {
    padding: 10px 0 0 0;
    list-style: none;
    margin: 5px 0 0 20px;
}
.uk-breadcrumb.add-form {
    padding: 20px 0 0 20px;
    margin: 0;    
}
.page ul.uk-breadcrumb {
    margin: 0 0 10px 0;
}
.uk-container.transaction-details .uk-label {
    margin-top: 18px;
    border: none;
    color: #fff;
}
label.uk-form-label.radio {
    cursor: pointer;
    float: left;
    margin-right: 10px;    
}
.radio-toolbar {
    width: 100%;
    display: block;
    margin: 5px 0 20px 0;
}
span.uk-label.status {
    background-color: #fff; 
}
.uk-navbar-container:not(.uk-navbar-transparent) {
    background-color: var(--primary-color);
    /* height: 60px;    */
}
.uk-section {
    padding: 60px 20px 20px 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    background-color: #F7F9FC;
}
.tm-main.uk-section.uk-section-default.sign-in {
    max-width: 600px;
    margin: 0 auto;
}
.content.sign-in {
    background-color: #fff;
    padding: 40px 60px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);    
    max-width: 640px;
    margin: 0 auto; 
    display: block;
}
.conditional-acceptance-of-vessel ol, .listing-agreement-addendum ol {
    padding-bottom: 20px;       
}
.conditional-acceptance-of-vessel ol li, .listing-agreement-addendum ol li {
    margin-bottom: 15px;        
}
.uk-position-relative.uk-margin-medium.add-deal, .uk-position-relative.uk-margin-medium.add-listing-agreement {
    width: 80%;
}
.uk-margin-medium {
    margin-bottom: 0;
}
*+.uk-hr, *+hr {
    margin: 0;
}
.uk-card.uk-card-default.uk-card-body.uk-width-1-1\@m {
    margin-bottom: 10px;
}
.uk-card.agreements {
    padding: 20px; 
}
.uk-search-default {
    width: 100%;
}
.uk-margin-small-right.uk-icon {
    width: 20px;
}
.uk-dropdown-nav>li>a, .uk-offcanvas-bar .uk-close {
    color: var(--body-color);
}
.uk-offcanvas-bar .uk-close:hover {
    color: var(--body-color);   
}
.uk-margin-small-right {
    margin-right: 0!important;
}
.uk-link, a {
    color: #004D89 !important;
    text-decoration: none;
    cursor: pointer;
}
.uk-padding-small {
    padding: 0 10px 10px 0;
}
*+.uk-table {
    margin: 10px 0;
}
.uk-table.deals, .uk-table.listing-agreements {
    border-collapse: separate;
    border-spacing: 0 10px;
}
.uk-table.deals tr, .uk-table.listing-agreements  tr {
    background-color: #fff;
    box-sizing: border-box; 
    box-shadow: 1px 1px 1px 1px rgb(63 107 158 / 20%);
}
.uk-table-hover tbody tr td,.uk-table-hover>tr td{cursor: pointer;}
.uk-table-hover tbody tr:hover,.uk-table-hover>tr:hover{background:none;}
.uk-table th {
    font-size: 13px;
    padding: 8px 12px;
    background-color: #E8EDF4;
    color: var(--body-color);
}
.uk-offcanvas-bar {
    color: var(--body-color);   
}
.uk-table {
    table-layout: fixed ;
}
.uk-table td {
    background-color: #fff;
    padding: 12px;
}
.reports-table {
    
}
td.checkmark-container {
    width: 5%;
}
td.alert  {
    width: 4%;
    padding-top: 23px;  
}
.uk-table-middle, .uk-table-middle td {
    vertical-align: top !important;
}
th.sort-column:after {
    background-image: url(../icons/chevron-down.svg);
    background-repeat: no-repeat;
    width: 16px;
    height: 8px;
    content: "";
    display: inline-block;
    margin-left: 9px;
}
table .primary {
    font-size: 18px;
    font-weight: 500;
    color: #364657;
    width: 30%;
}
table .link {
    font-size: 18px;
    font-weight: 500;
    color: #364657;
}
.uk-table-divider>:first-child>tr:not(:first-child), .uk-table-divider>:not(:first-child)>tr, .uk-table-divider>tr:not(:first-child) {
    border-top: 1px solid #D5DDE6;
}
.actions .uk-button-default {
    width: 40px;
    text-align: center;
    padding: 0;
    border-radius: 25px;
    height: 40px;
}
td.currency, th.currency {
    text-align: right;
    width: 120px;
}
tr.total td {
    background-color: #7D9ABA !important;
    color: #fff;
}
td.actions {
    text-align: right;
}
td.actions img {
    margin-right: 10px;
    outline: none;
}
.tm-sidebar-left .uk-nav-default>li>a {
    color: var(--sidebar-color) !important;
    font-size: 15px;
}
.tm-sidebar-right .uk-nav-default>li>a {
        color: #092540;
}
.tm-sidebar-left.broker {
    padding-left: 10px;
}
.tm-sidebar-left.broker .uk-nav-default>li:hover {
    background-color: #E8EDF4;
}
.tm-sidebar-left.broker .uk-nav-default>li>a:hover {
    color: var(--sidebar-active-color);
}
.tm-sidebar-left.broker .uk-nav-default>li.active>a {
    color: var(--sidebar-active-color);
    font-weight:600;
}
.uk-container>:last-child {
    margin-bottom: 0;
}
.uk-label, .uk-form-label {
        line-height: 1.5;
    font-size: .75rem;
    color: var(--body-color);
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 2px;
    margin: 0;
    padding: 5px 10px;
    border: 1px solid #ccc;
}
.uk-form-label {
    border: none;   
    color: var(--body-color);
}
.agreements.completed {
    border-left: 3px solid green;
}
.agreements.draft {
    border-left: 3px solid blue;
}
.uk-label.agreeement-status.out-for-signature, .uk-label.agreeement-status.up-next  {
    background-color: rgba(30, 135, 240, 0.2);
    margin: 0;
}
.uk-label.warning, .uk-label.in-review, .agreeement-status.draft {
    background-color: rgba(250, 160, 90, 0.2);
    margin: 0;
}
.uk-label.completed, .uk-label.verified, .agreeement-status.completed {
/*    background-color: rgba(50, 210, 150, 0.2); */
    margin: 0;
}
.agreements .title, .agreements .status {
    width: 40%;
}
.agreements .checkmark, .agreements .actions {
    width: 5%;
}
.iy-button-primary, .uk-button-primary , .uk-button-danger{
    background-color: #004D89;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: none;   
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

.uk-button-danger{
    background-color: #f70404;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: none;   
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

.uk-button-orange{
    background-color: #ff4000;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: none;   
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

.uk-button-default {
    color: #004D89;
    background-color: #fff;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: none;
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;     
}

.uk-button-dark {
    background-color: black;
    border-radius: 10px;
    color: #fff;
    border: 1px solid transparent;
    margin-right: 10px;
    text-transform: capitalize;
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

.uk-button-status{
    background-color: #17a2b8;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: capitalize;   
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

.uk-button-green{
    background-color: #10b326;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: capitalize;   
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

.uk-button-grey{
    background-color: #D3D3D3;
    color: #000000;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: capitalize;   
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
    cursor: pointer;
}

#btn-continue.small, .uk-button.small {
    height: 32px;
    padding: 0 10px;
    font-size: 14px;
    cursor: pointer;
}
.iy_dropdown {
    background-color: #F7F9FC;
    border: 1px solid #D5DDE6;
    border-radius: 3px;
    padding: 0 10px;
    height: 40px;
}
.uk-button.add-deal, .uk-button.add-listing-agreement {
/*  float: right;*/
    background-color: #0063A6;
    color: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    line-height: 1;
    height: 42px;
}
.tm-nav>li.uk-active:before {
    content: "";
    position: absolute;
    top: 15px;
    left: -25px;
    width: 15px;
    border-top: 1px solid #1e87f0;
}
.tm-sidebar-left {
    box-sizing: border-box;
    width: 240px !important;
    padding: 20px 10px 60px 0;
    overflow: auto;
    border-right: 1px solid #ccc;
    float: left;
    height: 100%;
    /*min-height: calc(100vh - 60px) ;*/
    min-height: calc(100vh - 85px) ; 
}
.tm-sidebar-left.transaction-details {
    padding: 10px 20px;
    float: left;
    position: relative;
}
.tm-sidebar-right {
    position: absolute;
    top: 0;
    left: calc(100% + 0px);
    width: 200px;
}
.sidebar-container {
    margin-left: 15px;
}
.uk-nav-default {
}
.uk-nav-default, .uk-form-label, .uk-offcanvas-bar .uk-form-label {
    font-size: 14px;
    line-height: 1;
    color: #5479A3; 
    margin-bottom: 5px;
    padding: 0;
    display: block;
}
.uk-input, .uk-select, .uk-textarea {
    background-color: #FFFFFF;  
    background-color: #F7F9FC !important; 
    border: 1px solid #D5DDE6 !important;
    border-radius: 3px;
}
.uk-text-muted {
    color: #777 !important;
    font-size: 13px;
    margin-bottom: 2px;
}
input.uk-input.small {
    width: 50%;
    display: block;
}
input[type='checkbox'] {
    margin-top: -1px;
}
.input-date {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    background-color: #fff;
        color: var(--body-color);
    border-radius: 3px;
    padding: 0 10px;
    border: 1px solid #D5DDE6;
    height: 34px;
}
.uk-form-danger {
    border-color: #dc8d99!important;
    background: #fff7f8!important;
    color: #d85030!important;
}
.uk-search-default .uk-search-input {
    max-width: 300px;
    width: 100%;
    background-color: #fff; 
    border: 1px solid #D5DDE6;
    border-radius: 3px; 
    padding-left: 10px;
}
.uk-search-default .uk-search-input:focus {
    background-color: #fff;
}

.uk-nav {
    padding: 10px 0;
}
ul.uk-nav li {
    cursor: pointer;
    padding: 10px 10px;
    text-align: left;
}
.broker ul.uk-nav li {
    padding: 5px 10px;
}
.user-dropdown {
	padding: 0px;
}

.user-dropdown li {
	padding: 3px 8px;
	border-bottom: 1px solid #ccc;
}

ul.uk-nav li.active, ul.uk-nav li.active i, .uk-nav-default>li.active>a {
    color: var(--sidebar-active-color);    
}
.broker li.active { 
    background-color: #E8EDF4;
    border-radius: 5px;    
}
ul.uk-nav li i {
    margin-right: 8px;
}
.uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before {
    margin: 0 10px 0 calc(10px - 4px);
}
.uk-logo img {
    height: 45px;
    width:45px;
}   
.password {
    float: left;
}
.forgot-password {
    float: right;
}
.sidebar-svg {
    margin-right: 10px;
    width: 22px;
}
.svg-container {
    float: left;
    width: 24px;
}
.svg {
    max-height: 24px;
}
.svg-medium {
    width: 18px;
    height: 18px;
}
.svg-small {
    width: 15px;
    height: 15px;
}
.add-icon {
    margin-right: 5px;
}
.headshot {
    width: 50px;
    height: 50px;
    margin-right: 5px;  
}
.task-status {
    width: 26px;
    height: 26px;
}
.uk-alert {
    text-align: center;
    color: #092540;
    border-radius: 5px;
    max-width: 400px;
    margin: 0 auto;
}
.uk-alert-danger {
    border: 1px solid #dc8d99;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 20px;
    line-height: 1;
}
.uk-alert-primary {
    background-color: #E7F6F4;
    border: 1px solid #32D296;
    color: #364657;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 20px;
    line-height: 1;
}
.uk-alert-primary .svg, .uk-alert-danger .svg {
    margin-right: 5px;
}
.checkboxes .uk-form-label {
    margin-right: 10px;
}
.uk-accordion li:not(:first-child) {
    border-top: 1px solid #D5DDE6;
    padding-top: 15px;
}   
.uk-accordion>:nth-child(n+2), .uk-accordion>:nth-child(n+2) {
    padding-top: 15px;  
    margin-top: 10px;
}
.uk-accordion-title.activities {
    font-size: 18px;
    text-transform: none;
}
.reports-sidebar.uk-accordion {
    margin-top: 0;
}   
.reports-sidebar.uk-accordion li:not(:first-child) {
    border-top: none;
    padding-top: 0;
}   

.uk-accordion-title {
    font-size: 14px;
    line-height: 1;
    color: #4E5C78;
    text-transform: uppercase;
}
.uk-accordion-content {
    margin-top: 10px !important;
}
a.uk-accordion-title {
    color: var(--sidebar-color) !important;
}
.transaction-detail-item {
    min-height: 300px;
    margin-bottom: 50px;    
}
.profile-photo {
    width: 50px;
    border: 1px solid #ccc;
    border-radius: 50px;
    height: 25px;
    padding: 5px 0 10px;
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}
select {
  padding: 0 35px 0 10px;
  border: 1px solid #D5DDE6;
  color: var(--body-color);
  font-size: 14px;
  height: 34px;
  min-width: 120px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../icons/arrow-down.svg) 94% / 15% no-repeat #fff;
}

select::-ms-expand { 
    display: none; /* remove default arrow on ie10 and ie11 */
}
.dashboard-row {
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
}
.dashboard-col {
    width: 45%;
    float: left;
    margin-right: 20px;
    max-width: 640px;
    margin-bottom: 10px;
}
.dashboard-col.wide {
    width: 100%;
    max-width: 640px;
}
.view-all {
    margin-bottom: 10px;
}
.view-more {
    margin-top: 15px;
    font-size: 14px;
    display: block;
}
.footer {
    color: #666;
    width: 100%;
    bottom: 0px;
    height: 36px;
    margin: 0px auto;
    z-index: 100;
    overflow: auto;
    position: fixed;
    font-size: 12px;
    max-width: 100%;
    line-height: 36px;
    padding-left: 18px;
    padding-right: 18px;
    background-color: #D5DDE6;
}
.footer .support-email {
    margin-left: 5px;
}
.rdt_Table {
    /* margin-bottom: 20px !important; */
}
.rdt_TableHeadRow {
    background-color: #D5DDE6;
    min-height: 36px !important;
}
.rdt_TableCol {
    background-color: #E8EDF4 !important;
}
.rdt_TableCol_Sortable {
    font-size: 14px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    color: #092540; 
    text-transform: uppercase;
}
.rdt_TableCell, .rdt_TableCol {
    font-family: "Source Sans Pro", sans-serif;
    padding: 0 10px !important;
}
.rdt_Pagination{
    margin-bottom: 70px ;
}
i.fa.fa-circle-o, i.fa.fa-check-circle, i.fa.fa-circle {
    margin-right: 0;
    font-size: 20px;
    color: var(--sidebar-color);
}
button#get-boat-history-report {
    margin-top: 20px;
    float: left;
}
span.uk-form-label.boat-history-report-label {
    float: left;
    margin-top: 30px;
    margin-left: 5px;
}
.dashboard th, .dashboard td {
    text-align: right;
    vertical-align: middle;
}
.dashboard td:first-child, .dashboard th:first-child {
    text-align: left;    
} 
.dashboard tr.total-row td {
    font-weight: bold;
    background-color: #E8EDF4;
}
.dashboard  th.in-house {
    text-align: right;
}
.dashboard th.value-commision {
    text-align: center;
}
button.btn-header {
    background: none;
    border: none;
    color: #fff;
}
.autocomplete-off {
    display: none;
}
.form-co-broker h3 {
    margin: 0 0 5px 0;
}
.info-icon, .btn-header {
    cursor: pointer;
}
.uk-button-secondary {
    background-color: #666;
    color: #fff;
    border: 1px solid transparent;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 3px;
    line-height: 36px;
}
/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    select {
        background:none\9;
        padding: 5px\9;
    } 
}

@media (min-width: 1200px) {
    .tm-sidebar-left+.tm-main {
        padding-left: 40px;
    }
    .tm-sidebar-right {
        left: calc(100% + 0px);
    }   
}

@media (min-width: 960px) {
    .tm-sidebar-left+.tm-main {
        padding-left: 200px;
    }
    .uk-container {
        padding-left: 10px;
        padding-right: 20px;
    }           
    .tm-sidebar-left+.tm-main {
        padding-left: 230px;
    }
}       
@media (max-width: 969px) {
    .tm-sidebar-left {
        position: fixed;
        background-color: white;
        z-index: 1;
    }
}
@media (max-width: 640px) {
    .content {
        padding: 10px;
    }    
    .uk-container {
        margin-left: 0px;
        margin-right: 0px;
    }
    .dashboard-col {
        width: 100%;
        margin-right: 0;
    }
}

.ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}